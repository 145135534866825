import {
    CompanyMetaFragment,
    GetProductsByCompanySlugQuery,
    MediaFragment,
} from '@graphql/generated/graphql';
import { getStrapiAssetUrl } from '@lib/strapi';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

type Props = {
    company: CompanyMetaFragment & { Logo?: MediaFragment };
    products: UnpackCollection<
        GetProductsByCompanySlugQuery,
        'companies',
        'Products'
    >[];
};

const CompanyMetaTagHead = ({ company, products }: Props) => {
    const router = useRouter();

    const imageUrl = getStrapiAssetUrl(company.Logo);
    const title = `Vos notices ${company.Name}`;
    const description = useMemo(
        () =>
            `Trouvez l'ensemble des informations concernant vos produits ${
                company.Name
            } : ${products.map((product) => product!.Name).join(', ')}.`,

        [products, company]
    );

    return (
        <Head>
            {/* Generic */}
            <title>{title}</title>
            <meta name='description' content={description} />

            {/* OpenGraph */}
            <meta property='og:title' content={title} />
            <meta property='og:description' content={description} />
            <meta
                property='og:url'
                content={`https://${company.Slug}.pimster.app`}
            />
            <meta property='locale' content={router.locale} />
            <meta property='og:image' content={imageUrl} />

            {/* Prevent zoon after input focus on Mozilla */}
            <meta
                name='viewport'
                content='width=device-width, height=device-height, initial-scale=1, minimum-scale=1, maximum-scale=1'
            />

            {/* Twitter */}
            <meta property='twitter:card' content='summary_large_image' />
            <meta
                property='twitter:url'
                content={`https://${company.Slug}.pimster.app`}
            />
            <meta property='twitter:title' content={title} />
            <meta property='twitter:image' content={imageUrl} />
        </Head>
    );
};

export default CompanyMetaTagHead;
