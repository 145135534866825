import NewTabLink from '@components/utils/links/newTabLink';
import ConditionalWrapper from '@components/utils/wrappers/conditionalWrapper';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
    OutboundLinkContext,
    OutboundLinkDestinationCategory,
    OutboundLinkShape,
} from '@enums';
import { GetCompanyQuery, MediaFragment } from '@graphql/generated/graphql';
import StrapiImage from '@lib/image/strapiImage';
import { getStrapiAssetUrl } from '@lib/strapi';

type Company = NonNullable<Unpacked<GetCompanyQuery['companies']>>;

type Props = {
    website?: NonNullable<Company['SocialLinks']>[0];
    logo: MediaFragment;
    name: string;
    hasBanner?: boolean;
};

const LOGO_SIZE = '6rem';

const sharedStyled = () => css`
    align-items: center;
    display: flex;
    justify-content: center;
`;

const Wrapper = styled.div<{ hasBanner?: boolean; hasWebsiteLink?: boolean }>`
    position: absolute;
    top: -100px;
    left: ${(props) => (props.hasBanner ? '0px' : 'auto')};
    width: ${(props) => (props.hasBanner ? LOGO_SIZE : '')};
    height: ${(props) => (props.hasBanner ? LOGO_SIZE : '')};
    border-radius: 100%;
    overflow: hidden;
    transform: translateZ(0);
    margin-inline: ${(props) => props.theme.spacing.lg};
    background-color: ${(props) => props.theme.palette.background.default};
    box-shadow: ${(props) =>
        props.hasWebsiteLink ? props.theme.shadows.default : ''};
    ${sharedStyled()};
    @media (max-width: 748px) {
        top: -80px;
    }
`;

const Link = styled(NewTabLink)`
    z-index: 1005;
    width: ${LOGO_SIZE};
    height: ${LOGO_SIZE};
    ${sharedStyled()};
`;

const LogoImage = styled(StrapiImage)`
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: center;
`;

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
`;

export const CompanyLogo = ({ website, logo, name, hasBanner }: Props) => {
    const hasWebsiteLink = Boolean(website?.Url && website?.LinksTo);

    return (
        <LogoContainer>
            <Wrapper hasBanner={hasBanner} hasWebsiteLink={hasWebsiteLink}>
                <ConditionalWrapper
                    hasToWrap={hasWebsiteLink}
                    wrapper={(children: JSX.Element) => (
                        <Link
                            href={website!.Url!}
                            destination_name={website!.LinksTo!}
                            destination_category={
                                OutboundLinkDestinationCategory.CompanyDocument
                            }
                            shape={OutboundLinkShape.Visual}
                            context={OutboundLinkContext.Header}
                        >
                            {children}
                        </Link>
                    )}
                >
                    <LogoImage
                        className='company-image'
                        src={getStrapiAssetUrl(logo)}
                        alt={logo?.alternativeText || `${name} logo`}
                        sizes='10rem'
                        formats={logo!.formats}
                    />
                </ConditionalWrapper>
            </Wrapper>
        </LogoContainer>
    );
};
