import InternalLink from '@components/utils/links/internalLink';
import Ripple from '@components/utils/ripple';
import { ROUTE_ONBOARDING, ROUTE_PRODUCT } from '@constants';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { CompanyProductMetaFragment } from '@graphql/generated/graphql';
import { useOnboarding } from '@hooks';
import StrapiImage from '@lib/image/strapiImage';
import { getStrapiAssetUrl } from '@lib/strapi';
import { Card } from '@lib/uikit';
import { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

type PropsType = {
    product: CompanyProductMetaFragment;
};

type LinkInfo = {
    pathname: string;
    as: LinkProps['as'];
};

const CardContainer = styled(Card)`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${(props) => props.theme.spacing.md};
    padding: ${(props) => props.theme.spacing.sm};
    margin-inline: ${(props) => props.theme.spacing.md};
    position: relative;
`;

const ImageContainer = styled.div`
    border-radius: ${(props) => props.theme.shape.radius.default};
    width: 3rem;
    height: 3rem;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SStrapiImage = styled(StrapiImage)`
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
`;

const Text = styled.h2`
    font: ${(props) => props.theme.fonts.heading5};
    flex-grow: 2;
    text-align: start;
    overflow-wrap: anywhere;
`;

const SInternalLink = styled(InternalLink)`
    width: 100%;
`;

const ProductItemList = ({ product }: PropsType) => {
    const theme = useTheme();
    const { company } = useRouter().query;
    const { Image: image, Name: name, Slug: slug } = product;
    const [linkInfo, setLinkInfo] = useState<LinkInfo>({
        pathname: ROUTE_PRODUCT,
        as: `/${slug}`,
    });
    const { shouldRedirectToOnboarding, isLoading } = useOnboarding({
        productSlug: product.Slug!,
    });

    useEffect(() => {
        if (!isLoading && shouldRedirectToOnboarding) {
            setLinkInfo({
                pathname: ROUTE_ONBOARDING,
                as: `/${slug}/onboarding`,
            });
        }
    }, [isLoading, shouldRedirectToOnboarding, slug, setLinkInfo]);

    return (
        <SInternalLink
            href={{
                pathname: linkInfo.pathname,
                query: { company: company, product: slug },
            }}
            as={linkInfo.as}
        >
            <CardContainer variant='outline'>
                <ImageContainer>
                    <SStrapiImage
                        className='product-image'
                        src={getStrapiAssetUrl(image?.[0])}
                        alt={image?.[0].alternativeText || `${name} image`}
                        sizes='10rem'
                        formats={image?.[0].formats}
                    />
                </ImageContainer>
                <Text as={'h2'}>{name}</Text>
                <Ripple color={theme.components.ripple.color} duration={500} />
            </CardContainer>
        </SInternalLink>
    );
};

export default ProductItemList;
