type Props = {
    hasToWrap: boolean;
    wrapper: (children: JSX.Element) => JSX.Element;
    children: JSX.Element;
};

const ConditionalWrapper = ({ hasToWrap, wrapper, children }: Props) =>
    hasToWrap ? wrapper(children) : children;

export default ConditionalWrapper;
