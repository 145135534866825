import styled from '@emotion/styled';
import {
    CompanyMetaFragment,
    GetCompanyQuery,
} from '@graphql/generated/graphql';
import StrapiImage from '@lib/image/strapiImage';
import { getStrapiAssetUrl } from '@lib/strapi';

type PropsType = {
    company: Unpacked<GetCompanyQuery['companies']> & CompanyMetaFragment;
};

const HeaderContainer = styled.div<{ hasLocales?: boolean }>`
    height: ${(props) => (props.hasLocales ? '5rem' : '9rem')};
    padding-bottom: ${(props) => props.theme.spacing.xxs};
`;

const FixedBanner = styled(StrapiImage)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10rem;
    object-fit: cover;
    z-index: -100;
    left: 50%;
    transform: translateX(-50%);
`;

const CompanyHeader = ({ company }: PropsType) => {
    const hasLocaleSwitchContent = company?.Locales?.length > 1;

    if (!company) return <></>;

    return (
        <>
            {company.BannerImage && (
                <FixedBanner
                    src={getStrapiAssetUrl(company.BannerImage)}
                    formats={company.BannerImage?.formats}
                    alt={company.BannerImage?.alternativeText || company.Name}
                />
            )}
            <HeaderContainer hasLocales={hasLocaleSwitchContent} />
        </>
    );
};

export default CompanyHeader;
