import CompanyHeader from '@components/company/companyHeader';
import { CompanyLogo } from '@components/company/companyLogo';
import Footer from '@components/containers/footer';
import CompanyMetaTagHead from '@components/head/companyMetadata';
import FaviconHead from '@components/head/favicons';
import AppLayout from '@components/layouts/appLayout';
import ProductCollection from '@components/product/productCollection';
import { COMMON, HOME, SECONDS_PER_DAY } from '@constants';
import styled from '@emotion/styled';
import { TrackEvent } from '@enums';
import {
    CompanyProductMetaFragment,
    Enum_Modals_Trigger,
    GetCompanyDocument,
    GetCompanyQuery,
    GetProductsByCompanySlugDocument,
    GetProductsByCompanySlugQuery,
} from '@graphql/generated/graphql';
import { useAnalytics } from '@lib/analytics/analyticsContext';
import { getCompanyConfig } from '@lib/uikit';
import { PageWithCompany } from '@types';
import { getLastVisited, updateLastVisited } from '@utils/lastVisited';
import {
    withApolloClient,
    withCompany,
    withErrorsGSP,
} from '@utils/serverMethodsPlugins';
import { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import { useRouter } from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useEffect } from 'react';

interface Props extends PageWithCompany {
    company: NonNullable<Unpacked<GetCompanyQuery['companies']>>;
    products: CompanyProductMetaFragment[];
}

const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: ${(props) => props.theme.spacing.md};
    margin-bottom: ${(props) => props.theme.spacing.md};
    gap: ${(props) => props.theme.spacing.sm};
    background-color: ${(props) => props.theme.palette.background.default};
    border-radius: ${(props) => props.theme.shape.radius.default};
`;

const LogoContainer = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Index: NextPage<Props> = ({ companyMeta, company, products }: Props) => {
    const { query } = useRouter();
    const { track } = useAnalytics();

    useEffect(() => {
        const lastVisited = getLastVisited(query.company);

        if (!lastVisited.company) {
            track(TrackEvent.CompanyFirstVisit, {
                event: Enum_Modals_Trigger.CompanyFirstVisit,
                company: query.company as string,
            });
        }

        updateLastVisited(query.company, 'company');
    }, [query.company, track]);

    return (
        <>
            <CompanyMetaTagHead
                company={{ ...companyMeta, Logo: company.Logo }}
                products={products}
            />
            <FaviconHead />
            <AppLayout
                locales={companyMeta.Locales}
                withBodyPadding={false}
                bodyOverHeader={true}
                hasFixedHeader={false}
                header={
                    <CompanyHeader company={{ ...companyMeta, ...company }} />
                }
                body={
                    <BodyContainer>
                        <LogoContainer>
                            <CompanyLogo
                                logo={company.Logo!}
                                name={companyMeta.Name}
                                hasBanner={Boolean(company?.BannerImage)}
                                website={company?.SocialLinks?.find(
                                    (el) => el?.LinksTo === 'Website'
                                )}
                            />
                        </LogoContainer>
                        <ProductCollection
                            productCollection={company.ProductCollection}
                        />
                    </BodyContainer>
                }
                footer={<Footer company={company} />}
            />
        </>
    );
};

export const getStaticPaths: GetStaticPaths = () => {
    return {
        paths: [],
        fallback: 'blocking',
    };
};

export const getStaticProps: GetStaticProps = withErrorsGSP<Props>(
    withApolloClient<Props>(
        withCompany<Props>(
            async ({
                apolloClient,
                company: companyMeta,
                locale,
                defaultLocale,
            }) => {
                const {
                    data: { companies: companiesWithProducts },
                } = await apolloClient.query<GetProductsByCompanySlugQuery>({
                    query: GetProductsByCompanySlugDocument,
                    variables: { locale, companySlug: companyMeta.Slug },
                });

                const companyWithProducts = companiesWithProducts?.[0];

                if (!companyWithProducts) {
                    return { notFound: true };
                }

                if (companiesWithProducts.length > 1) {
                    throw new Error(
                        `Invalid number of companies received in GSSP at [company]/index.tsx for company slug: ${companyMeta.Slug} and locale ${locale}`
                    );
                }

                const products = companyWithProducts.Products;

                if (!products?.length) {
                    return { notFound: true };
                }

                const { data: companyData } =
                    await apolloClient.query<GetCompanyQuery>({
                        query: GetCompanyDocument,
                        variables: { locale, slug: companyMeta.Slug },
                    });

                const company = companyData.companies?.[0];

                if (!company) {
                    throw new Error(
                        `Invalid company data received in GSSP at [company]/index.tsx`
                    );
                }

                if (products.length === 1) {
                    const productSlug = products[0].Slug;

                    return {
                        redirect: {
                            destination: `/${locale}/${productSlug}`,
                            permanent: false,
                        },
                    };
                }

                return {
                    props: {
                        company,
                        products,
                        ...(await serverSideTranslations(
                            locale ?? defaultLocale!,
                            [COMMON, HOME]
                        )),
                        ...(await getCompanyConfig(
                            apolloClient,
                            companyMeta.Slug!,
                            locale ?? defaultLocale!
                        )),
                    },
                    revalidate: SECONDS_PER_DAY,
                };
            }
        )
    )
);

export default Index;
